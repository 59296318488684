// panel keys
export const ICD10 = 'icd10'
export const KCODE = 'kcode'
export const JOA_MEMBER = 'joa-member'
export const FACILITY = 'facility'
export const JSIS_MEDICAL_METHOD = 'jsis_medical_method'
export const JSIS_DISEASE = 'jsis_disease'
export const POSTAL_CODE_SEARCH = 'postal_panel'
export const INSTALLATION_DATE = 'rm_implant_installation_date'
export const DEFINITION = 'definition'
export const ALERT = 'alert'
export const ITEM_CODE = 'item_code'
export const ITEM_MAKER = 'item_maker'
export const ITEM_BARCODE = 'item_barcode'
export const OPE_METHOD = 'ope_method'
export const OPE_METHOD_OFTEN = 'ope_method_often'
export const OPE_METHOD_ALL = 'ope_method_all'
export const DISEASE_CODE = 'disease_code'
export const DISEASE_CODE_OFTEN = 'disease_code_often'
export const DISEASE_NAME = 'disease_name'
export const BARCODE_FOLDER = 'barcode_folder'

// component types
export const TEXT_FIELD_COMPONENT_TYPE = 'text_field'
export const TEXT_AREA_COMPONENT_TYPE = 'text_area'
export const CHECK_BOX_COMPONENT_TYPE = 'check_box'
export const DATE_COMPONENT_TYPE = 'date_component'
export const TIME_COMPONENT_TYPE = 'time_component'
export const SWITCHED_TEXT_FIELD_COMPONENT_TYPE = 'switched_text_field'
export const SWITCHED_DATE_COMPONENT_TYPE = 'switched_date_component'
export const SWITCHED_TIME_COMPONENT_TYPE = 'switched_time_component'
export const AGE_COMPONENT_TYPE = 'age_component'
export const RADIO_MATRIX_COMPONENT_TYPE = 'radio_matrix'
export const CHECK_MATRIX_COMPONENT_TYPE = 'check_matrix'
export const SELECT_BOX_COMPONENT_TYPE = 'select_box'
export const RADIO_MATRIX_WITH_DATETIME_COMPONENT_TYPE =
  'radio_matrix_with_datetime'
export const PANEL_SELECT_COMPONENT_TYPE = 'panel_select'
export const COMBO_PANEL_COMPONENT_TYPE = 'combo_panel'
export const OPE_DOCTOR_COMPONENT_TYPE = 'ope_doctor'
export const ITEM_COMPONENT_TYPE = 'item_component'
export const WRAPPER_COMPONENT_TYPE = 'wrapper_component'
export const LABEL_COMPONENT_TYPE = 'label_component'
export const TITLE_COMPONENT_TYPE = 'title_component'
export const OPE_METHOD_COMPONENT_TYPE = 'ope_method'
export const MULTIPLE_COMPONENT_TYPE = 'multiple_component'
export const TREE_SELECT_BOX_COMPONENT_TYPE = 'tree_select_box'

// component types that have answer/value as object
export const OBJ_ANSWER_COMPONENT_TYPES = [
  'switched_text_field',
  'combo_panel',
  'switched_date_component',
  'switched_time_component',
  'age_component',
  'panel_select',
  'radio_matrix_with_datetime'
]
// component types that always have answer/value as array
export const ARR_ANSWER_COMPONENT_TYPES = ['check_matrix', 'tree_select_box']

// component types that have text input field
export const TEXT_INPUT_COMPONENT_TYPES = [
  'text_field',
  'switched_text_field',
  'combo_panel'
]

export const ITEM_COMP_KEYS_READONLY_IF_SELECTED = [
  'maker',
  'item_name',
  'mat',
  'size',
  'mat_other'
]

export const CONDITION_PREFIX_STRING = {
  VALUE: '::value',
  LENGTH: '::length',
  GEN_ITEM_LENGTH: '::gen_item_len'
}

export const CONDITION_SHEET_REF_STRING = {
  BASIC: 'basic##'
}

export const GEN_ITEM_COMPONENT_TYPES = [
  'radio_matrix',
  'check_matrix',
  'select_box'
]

export const ERROR_MESSAGES = {
  RETRY_LATER: 'しばらく時間をおいてから再度お試しください。',
  MINUTE_REQUIRED: '分を選択してください',
  LENGTH_WITHIN: '文字以内で入力してください',
  NUMERIC_REQUIRED: '値は数値で入力してください'
}

export const WARNING_MESSAGES = {
  AMBIGUOUS_DISEASE:
    '選択された術式に対し部位が明確な病名も併せて選択して下さい',
  INTRAARTICULAR_FRACTURE_PROCEDURES:
    '関節内骨折（関節脱臼骨折を含む）の病名は、関節名、傷病部位名、骨の名称が特定できるように登録をお願いいたします。',
  OPE_TIME_RANGE_BY_PROCEDURES:
    '手術時間が標準的な範囲から外れています。今一度、入力内容をご確認ください。',
  SECONDARY_PROCEDURES:
    '骨移植術以外の術式を含む場合、骨移植術は主たる術式１以外へ移動して下さい。',
  JSSR_SOCIETY_PROCEDURES:
    '主たる術式に脊椎の手術を含む場合、データ提供レジストリーは「脊椎」をご選択ください。'
}

export const OPE_METHOD_DEFAULT_VALUE = {
  id: null,
  sub_id: null,
  name: null,
  places: '1'
}

export const DISEASE_NAME_DEFAULT_VALUE = {
  icd10_2013: null,
  icd10_2013_sub: null,
  byomei_kanri_code: null,
  name: null
}

// data keys
export const KEY_OPE_METHOD_MAIN = 'ope_method_main'
export const KEY_OPE_METHOD_SUB = 'ope_method_sub'

// Organization Short Name
export const ORGANIZATION_SHORT_NAME_EN = {
  JOA: 'JOA',
  JAR: 'JAR',
  JOSKAS: 'JSOA',
  JSIS: 'JSIS',
  JSSF: 'JSSF',
  JSSR: 'JSSR',
  EOS: 'EOS'
}
export const BARCODE_FOLDER_MODAL_STATES = {
  SELECT_FOLDER: 'select-folder',
  CHECK_ITEMS: 'check-items'
}

export const BARCODE_FOLDER_MATCH_STATUS = [
  'perfect_fits',
  'so_closes',
  'duplicates',
  'for_others',
  'out_of_scopes'
]

export const CASE_EDIT_TARGET_SECTION_ID = 'case-edit-target-section'
